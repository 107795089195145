<script lang="ts" context="module">
  import SmoreLogo from "./SmoreLogo.svg";
  import { getShowContext } from "./showContext";
  import AccessibilityButton from "./AccessibilityButton.svelte";
  import ReactButton from "./ReactButton.svelte";
  import ShareButton from "./ShareButton.svelte";
  import TranslateButton from "./TranslateButton.svelte";
  import { formatKMBT } from "@ui/numberUtils";
</script>

<script lang="ts">
  const context = getShowContext();
  let isSharePopupOpen: boolean = false;
  let isReactPopupOpen: boolean = false;
  let isTranslatePopupOpen: boolean = false;
  let isAccessibilityPopupOpen: boolean = false;

  let showMiddleBarWithViewCount =
    (context.showTranslations || context.showSocialIcons || context.showAccessibilityIcons) && context.showViewCount;
  let showMiddleBarOnly = (context.showTranslations || context.showSocialIcons || context.showAccessibilityIcons) && !context.showViewCount;
</script>

{#if !context.hideToolbar}
  <div class="toolbar ada-bg-white fixed left-0 right-0 top-0 z-50 m-auto h-12 rounded-b-lg border-t-4 bg-white px-4 shadow-lg filter">
    <div class="content mx-auto flex h-full items-center justify-between">
      {#if context.showPromotionBar}
        <div aria-hidden="true" class="clickable -mt-1 flex-shrink-0">
          <a href="/app?s=logo" class="mt-1.5 inline-block h-full">
            <img class="w-24" src={SmoreLogo} alt="Smore logo" />
          </a>
        </div>
      {/if}

      <div
        class="-mt-2 flex h-full items-center"
        class:ml-6={showMiddleBarOnly && context.showPromotionBar}
        class:mx-8={showMiddleBarWithViewCount && context.showPromotionBar}
        class:mr-8={showMiddleBarWithViewCount && !context.showPromotionBar}
        class:sm:mx-6={showMiddleBarWithViewCount && context.showPromotionBar}
        class:sm:mr-6={(showMiddleBarWithViewCount && !context.showPromotionBar) ||
          (context.showPromotionBar && context.showViewCount && !showMiddleBarOnly)}
        class:md:mx-10={showMiddleBarWithViewCount && context.showPromotionBar}
        class:md:mr-6={showMiddleBarWithViewCount && !context.showPromotionBar}>
        {#if context.showSocialIcons}
          <!-- This wrapper is needed for the divider border color -->
          <div class="menu-button-wrapper flex h-full w-full items-center">
            <!-- This wrapping div is needed for the active border color -->
            <div class="menu-button flex h-full w-full items-center border-t-4" class:active={isSharePopupOpen}>
              <ShareButton bind:isOpen={isSharePopupOpen} />
            </div>
          </div>
        {/if}
        {#if context.showTranslations}
          <div class="menu-button-wrapper flex h-full w-full items-center">
            <div class="menu-button flex h-full w-full items-center border-t-4" class:active={isTranslatePopupOpen}>
              <TranslateButton bind:isOpen={isTranslatePopupOpen} />
            </div>
          </div>
        {/if}
        {#if context.showAccessibilityIcons}
          <div class="menu-button-wrapper flex h-full w-full items-center">
            <div
              class="menu-button ada-menu-button flex h-full w-full items-center rounded-br-lg border-t-4"
              class:active={isAccessibilityPopupOpen}>
              <AccessibilityButton bind:isOpen={isAccessibilityPopupOpen} />
            </div>
          </div>
        {/if}
      </div>
      {#if context.showViewCount}
        <div
          role="text"
          aria-label="View count: {formatKMBT(context.viewCount)}"
          class="notranslate -mt-2 flex h-full w-full select-none items-center rounded border-t-4 text-15 font-bold text-nxgray-600">
          <span aria-hidden="true" class="material-icons notranslate mr-0.5 text-18">remove_red_eye</span>
          <span class="text-15 font-bold">{formatKMBT(context.viewCount)}</span>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style>
  .toolbar {
    width: fit-content;
    max-width: 100%;
    margin: auto;
  }

  :global(.translated-rtl .toolbar),
  :global(.translated-ltr .toolbar) {
    /* Add margin for the Google Translate widget */
    top: 40px;
  }

  @media (min-width: 640px) {
    .content {
      /* width: 630px; */
    }
  }

  .menu-button:hover,
  .menu-button:not(.active):hover {
    border-color: theme("borderColor.nxgray.300");
  }
  .menu-button.active {
    border-color: theme("borderColor.nxgray.500");
  }

  .menu-button-wrapper + .menu-button-wrapper {
    margin-left: theme("margin.6");
  }
</style>
